<template>
  <div>
    <a-card :title="$t('创建移库单')">
      <a-button slot="extra" type="primary" ghost icon="left" @click="$router.go(-1)">{{ $t("返回") }}</a-button>

      <a-spin :spinning="createLoading">
        <a-form-model ref="form" :model="orderForm" :rules="rules" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
          <a-row>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="number" :label="$t('移库单号')">
                <a-input v-model="orderForm.number" allowClear />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="warehouse" :label="$t('移库仓库')">
                <a-select v-model="orderForm.warehouse" style="width: 100%" @change="onChangeWarehouse">
                  <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="remark" :label="$t('备注')">
                <a-input v-model="orderForm.remark" allowClear />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider></a-divider>

        <div>
          <a-space>
            <a-button type="primary" @click="openMaterialModal">{{ $t("产品移库") }}</a-button>
            <a-button type="primary" @click="openPalletModal">{{ $t("整托移库") }}</a-button>
            <a-button type="primary" @click="openLocationModal">{{ $t("整库位移库") }}</a-button>
          </a-space>

          <div style="margin-top: 16px">
            <a-table rowKey="id" size="small" :columns="columns" :data-source="materialItems" :pagination="false">
              <div slot="action" slot-scope="value, item, index">
                <a-button-group size="small">
                  <a-button type="danger" @click="removeMaterial(index)">{{ $t("删除") }}</a-button>
                </a-button-group>
              </div>
            </a-table>
          </div>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定创建吗?')" @confirm="create">
          <a-button type="primary" :loading="createLoading">{{ $t("创建") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <MaterialTransderModal v-model="materialModalVisible" :warehouse="orderForm.warehouse" @add="addMaterial" />
    <PalletTransderModal v-model="palletModalVisible" :warehouse="orderForm.warehouse" @add="addPallet" />
    <LocationTransderModal v-model="locationModalVisible" :warehouse="orderForm.warehouse" @add="addLocation" />
  </div>
</template>

<script>
import { warehouseOption } from "@/api/option";
import { locationTransferOrderNumber, locationTransferOrderCreate } from "@/api/stockTransfer";
import { columns } from "./columns";
import { rules } from "./rules";

export default {
  components: {
    MaterialTransderModal: () => import("./MaterialTransderModal"),
    PalletTransderModal: () => import("./PalletTransderModal"),
    LocationTransderModal: () => import("./LocationTransderModal"),
  },
  inject: ["reloadPage"],
  data() {
    return {
      rules,
      columns,

      orderForm: { number: undefined, warehouse: undefined, remark: undefined },
      materialItems: [],

      materialModalVisible: false,
      palletModalVisible: false,
      locationModalVisible: false,

      createLoading: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
    defaultWarehouse() {
      return this.$store.state.user.defaultWarehouse;
    },
  },
  methods: {
    initData() {
      locationTransferOrderNumber().then((data) => {
        this.orderForm.number = data.number;
      });

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
        this.orderForm = { ...this.orderForm, warehouse: this.defaultWarehouse };
      });
    },
    openMaterialModal() {
      if (!this.orderForm.warehouse) {
        this.$message.warn(this.$t("请先选择仓库"));
        return;
      }

      this.materialModalVisible = true;
    },
    openPalletModal() {
      if (!this.orderForm.warehouse) {
        this.$message.warn(this.$t("请先选择仓库"));
        return;
      }

      this.palletModalVisible = true;
    },
    openLocationModal() {
      if (!this.orderForm.warehouse) {
        this.$message.warn(this.$t("请先选择仓库"));
        return;
      }

      this.locationModalVisible = true;
    },
    addMaterial(item) {
      this.materialItems.push(item);
    },
    addPallet(item) {
      this.materialItems.push(item);
    },
    addLocation(item) {
      this.materialItems.push(item);
    },
    onChangeWarehouse() {
      this.materialItems = [];
    },
    removeMaterial(index) {
      this.materialItems.splice(index, 1);
    },
    create() {
      if (this.materialItems.length == 0) {
        this.$message.warn(this.$t("未添加移库产品"));
        return;
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.createLoading = true;
          const formData = { ...this.orderForm, location_transfer_material_items: this.materialItems };
          locationTransferOrderCreate(formData)
            .then((data) => {
              this.$message.success(this.$t("创建成功"));
              this.$router.go(-1);
            })
            .finally(() => {
              this.createLoading = false;
            });
        }
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
